import PrimaryButton from "@components/atoms/buttons/PrimaryButton";
import SecondaryButton from "@components/atoms/buttons/SecondaryButton";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import { Fragment } from "react";

export default function NotFoundpage() {
  const content = {
    nl: {
      title: "Deze pagina bestaat niet",
      home_action: "Ga terug naar home",
      support_action: "Contacteer support",
    },
    en: {
      title: "This page does not exist",
      home_action: "Go back home",
      support_action: "Contact support",
    },
    fr: {
      title: "Cette page n'existe pas",
      home_action: "Volver a la página de inicio",
      support_action: "Contactez le support",
    },
  };
  const router = useRouter();

  return (
    <Fragment>
      <NextSeo noindex={true} nofollow={true} />
      <div className="min-h-full bg-white px-4 py-24 sm:px-6 sm:py-64 md:grid md:place-items-center lg:px-8">
        <div className="mx-auto max-w-max">
          <main className="sm:flex">
            <p className="text-4xl font-extrabold text-ifixers sm:text-5xl">
              404
            </p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-extrabold tracking-tight text-gray-700 sm:text-5xl">
                  {content[router.locale || router.defaultLocale]?.title}
                </h1>
                <p className="mt-1 text-base text-gray-500"></p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <PrimaryButton text={content[router.locale].home_action} />

                <SecondaryButton text={content[router.locale].support_action} />
              </div>
            </div>
          </main>
        </div>
      </div>
    </Fragment>
  );
}
