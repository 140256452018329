const SecondaryButton = ({ text, title, type }) => {
  return (
    <button
      title={title}
      type={type}
      className="transition inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-ifixers bg-ifixers-100 hover:bg-ifixers-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifixers-500;"
    >
      {text}
    </button>
  );
};

export default SecondaryButton;
