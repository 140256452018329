const PrimaryButton = ({ text, title }) => {
  return (
    <button
      title={title}
      className="transition inline-flex items-center tracking-wide px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-ifixers-700 hover:bg-ifixers-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifixers-500"
    >
      {text}
    </button>
  );
};

export default PrimaryButton;
